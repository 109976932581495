var moment = require('moment');


exports.toFriendlyDateTime = (date) => {
    var dt = moment(date);
    var current = moment();

    // if same day, return time
    if (dt.isSame(current, 'day')) {
    	return dt.format('H:mm');
    } else if (dt.isSame(current, 'month') || dt.isSame(current, 'year')) {
    	return dt.format('D MMM H:mm');
    } else {
    	return dt.format('D/M/YY H:mm');
    }

}

exports.sessionDateTime = (date) => {
    var dt = moment(date);
    var current = moment();

    // if same day, return time
    if (dt.isSame(current, 'day')) {
    	return dt.format('H:mm');
    } else if (dt.isSame(current, 'month') || dt.isSame(current, 'year')) {
    	return dt.format('D MMM H:mm');
    } else {
    	return dt.format('D/M/YY H:mm');
    }

}