<template>
    <v-app id="main-wrapper">
        <header class="topbar">
            <nav class="navbar top-navbar navbar-expand-md navbar-light">
                <div class="navbar-header">
                    <a class="navbar-brand" href="#">
                        <!-- Logo icon --> <b>
                            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                            <!-- Dark Logo icon --> <img :src="'images/logo-icon.png'" alt="homepage" class="dark-logo">
                            <!-- Light Logo icon --> <img :src="'images/logo-light-icon.png'" alt="homepage"
                                class="light-logo">
                        </b>
                        <!--End Logo icon -->
                        <!-- Logo text --> <span style="">
                            <!-- dark Logo text -->
                            <img :src="'images/logo-light-text.png'" class="light-logo" alt="homepage"></span>
                    </a>
                </div>
                <div class="navbar-collapse">
                    <ul class="navbar-nav mr-auto mt-md-0">
                        <!-- This is  -->
                        <li class="nav-item"><a
                                class="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark"
                                href="javascript:void(0)"><i class="ti-menu"></i></a></li>
                        <li class="nav-item"><a
                                class="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark"
                                href="javascript:void(0)"><i class="ti-menu"></i></a></li>
                        <li class="nav-item dropdown">
                            <a style="display:none"
                                class="nav-link dropdown-toggle text-muted text-muted waves-effect waves-dark" href=""
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i
                                    class="mdi mdi-bell"></i>
                                <div class="notify"> <span class="heartbit"></span> <span class="point"></span> </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right mailbox scale-up">
                                <ul>
                                    <li>
                                        <div class="drop-title">Notifications</div>
                                    </li>
                                    <li>
                                        <div class="message-center">
                                            <!-- Message -->
                                            <a href="#">
                                                <div class="btn btn-danger btn-circle"><i class="fa fa-link"></i></div>
                                                <div class="mail-contnet">
                                                    <span class="mail-desc"><b>Tanti</b> mengalihkan pesan
                                                        <b>"0575896532"</b> pada anda <b>"tolong ini dibales
                                                            ya"</b></span>
                                                </div>
                                                <span class="time-stamp">9:10 AM
                                                </span>
                                            </a>
                                            <!-- Message -->
                                            <a href="#">
                                                <div class="btn btn-danger btn-circle"><i class="fa fa-link"></i></div>
                                                <div class="mail-contnet">
                                                    <span class="mail-desc"><b>Tanti</b> mengalihkan pesan
                                                        <b>"0575896532"</b> pada anda <b>"tolong ini dibales
                                                            ya"</b></span>
                                                </div>
                                                <span class="time-stamp">9:10 AM
                                                </span>
                                            </a>
                                            <!-- Message -->
                                            <a href="#">
                                                <div class="btn btn-danger btn-circle"><i class="fa fa-link"></i></div>
                                                <div class="mail-contnet">
                                                    <span class="mail-desc"><b>Tanti</b> mengalihkan pesan
                                                        <b>"0575896532"</b> pada anda <b>"tolong ini dibales
                                                            ya"</b></span>
                                                </div>
                                                <span class="time-stamp">9:10 AM
                                                </span>
                                            </a>
                                            <!-- Message -->
                                            <a href="#">
                                                <div class="btn btn-danger btn-circle"><i class="fa fa-link"></i></div>
                                                <div class="mail-contnet">
                                                    <span class="mail-desc"><b>Tanti</b> mengalihkan pesan
                                                        <b>"0575896532"</b> pada anda <b>"tolong ini dibales
                                                            ya"</b></span>
                                                </div>
                                                <span class="time-stamp">9:10 AM
                                                </span>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <a class="nav-link text-center" href="javascript:void(0);"> <strong>Check all
                                                notifications</strong> <i class="fa fa-angle-right"></i> </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <b-nav pills class="navbar-nav my-lg-0 ">
                        <li class="status-list">{{alias}}</li>
                        <li class="status-list">
                            <!-- <i class="mdi" v-bind:class="{'mdi-lan-disconnect red-color': !internetConnected, 'mdi-lan-connect green-color': internetConnected}"></i> -->
                            <span class="text-muted">Online</span> <i style="" class="mdi mdi-circle green-color"></i>
                            <!-- <span class="text-muted">Offline</span> <i style="" class="mdi mdi-circle text-secondary"></i> -->
                        </li>
                        <!-- <li class="status-list"><i class="mdi "
                                v-bind:class="{'mdi-network-strength-4 green-color': success, 'mdi-network-strength-off-outline red-color': !success}"></i>
                        </li> -->
                        <!-- <li class="status-list"><i v-bind:class="batteryCssClass(battery)"></i> {{battery}}%
                        </li> -->
                        <!-- <li class="status-list"></li> -->




                        <b-nav-item-dropdown class="nav-item dropdown dropdown-user" id="dropdown-right" right
                            text="Left align">
                            <template v-slot:button-content>
                                <span class="nav-link dropdown-toggle text-muted waves-effect waves-dark"><img
                                        :src="'images/dummy.jpg'" alt="user" class="profile-pic"></span>
                            </template>
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-img">
                                        <img :src="'images/dummy.jpg'" alt="user">
                                    </div>
                                    <div class="u-text">
                                        <h4>{{nameUser}}</h4>
                                        <p class="text-muted">{{emailUser}}</p>
                                        <a class="btn btn-rounded btn-danger btn-sm text-light"
                                            @click="showProfileDialog">
                                            Ubah Profil</a>

                                    </div>
                                </div>
                            </li>
                            <b-dropdown-item role="separator" class="divider"></b-dropdown-item>
                            <!-- <li>
                                <router-link :to="{name: 'dashboard'}"><i class="ti-bar-chart mr-2"></i>
                                    Dashboard</router-link>
                            </li> -->
                            <li v-if="hasUiAccess(CALENDAR)">
                                <router-link :to="{name: 'calendar'}"><i class="ti-calendar mr-2"></i>
                                    Kalender</router-link>
                            </li>
                            <li v-if="hasUiAccess(PRESENCE)">
                                <router-link :to="{name: 'set presence'}"><i class="ti-time mr-2"></i>
                                    Waktu Kerja</router-link>
                            </li>
                            <li v-if="hasUiAccess(COMMCH)">
                                <router-link :to="{name: 'commchannel'}"><i class="ti-direction-alt mr-2"></i>
                                    Kanal</router-link>
                            </li>
                            <li v-if="hasUiAccess(USER)">
                                <router-link :to="{name: 'user'}"><i class="ti-user mr-2"></i>
                                    Pengguna</router-link>
                            </li>
                            <li v-if="hasUiAccess(SETTING)">
                                <router-link :to="{name: 'setting'}"><i class="ti-settings mr-2"></i>
                                    Pengaturan</router-link>
                            </li>
                            <b-dropdown-item role="separator" class="divider"></b-dropdown-item>
                            <li><a href="#" @click="confirmLogout"><i class="fa fa-power-off mr-2"></i>
                                    Logout</a>
                            </li>
                        </b-nav-item-dropdown>
                        <!-- ============================================================== -->
                        <!-- Language -->
                        <!-- ============================================================== -->
                    </b-nav>
                </div>
            </nav>

        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar" id="slideLeft" data-scrollbar="true" tabindex="1"
                style="overflow: hidden; outline: none; height: 100%">
                <!-- User profile -->
                <!-- End User profile text-->
                <!-- Sidebar navigation-->
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <li class="">
                            <button title="Buat pesan baru" @click="$bvModal.show('new-message-modal')"
                                class="btn btn-primary create-message "><i style=""
                                    class="mdi mdi-message-plus"></i><span class="ml-1 hide-menu"> Buat
                                    Pesan</span></button>
                        </li>
                        <li v-if="hasUiAccess(UNASSIGNED)" @click="closeMenu">
                            <router-link aria-expanded="false" class="waves-effect waves-dark"
                                v-bind:class="{'active': $router.currentRoute.name == 'unassigned'}" to="/unassigned/">
                                <i class="mdi mdi-inbox"></i><span class="hide-menu"
                                    :class="{'font-weight-bold': newMessageIsActive == true }">Belum Ditangani
                                </span><span class="list-operator badge badge-notif" v-show="unreadUnassigned > 0"
                                    v-bind:class="{'badge-notif-active': $router.currentRoute.name == 'unassigned'}">
                                    {{ unreadUnassigned }}</span></router-link>
                        </li>
                        <li v-if="hasUiAccess(MY_CONV)" @click="closeMenu">
                            <router-link class="waves-effect waves-dark"
                                :to="{name: 'myconversation', query: {status: 'open', sortOrder: 'lastActive', sortDir: 'desc', labels: '', custroles: '', lastMessagesDirection: '', stars: 'false', channel: ''}}"
                                aria-expanded="false"
                                v-bind:class="{'active': $router.currentRoute.name == 'myconversation'}"><i
                                    class="mdi mdi-account-check"></i><span class="hide-menu"
                                    :class="{'font-weight-bold': mynewMessageIsActive == true }">Pesan Saya</span><span
                                    class="list-operator badge badge-notif" v-show="unreadMyChat > 0"
                                    v-bind:class="{'badge-notif-active': $router.currentRoute.name == 'myconversation'}">
                                    {{ unreadMyChat }}</span>
                            </router-link>
                        </li>
                        <li v-if="hasUiAccess(ALL_MSG) && !isAccountTest" @click="closeMenu">
                            <router-link class="waves-effect waves-dark"
                                :to="{name: 'allmessage', query: {status: 'open', sortOrder: 'lastActive', sortDir: 'desc', labels: '', custroles: '', lastMessagesDirection: '', stars: 'false', operatorId: '', channel: ''}}"
                                aria-expanded="false"
                                v-bind:class="{'active': $router.currentRoute.name == 'allmessage'}"><i
                                    class="mdi mdi-message-text"></i><span class="hide-menu">Semua
                                    Pesan </span><span v-show="countAllNewMessage > 0"
                                    v-bind:class="{'badge-notif-active': allMessageIsActive == true, 'badge badge-notif': allMessageIsActive == false}">{{ countAllNewMessage }}</span>
                            </router-link>
                        </li>
                        <!-- <li v-if="hasUiAccess(COMMENT)" @click="closeMenu">
                            <router-link class="waves-effect waves-dark" :to="{ name: 'comments', query: { sortOrder: 'lastActive', sortDir: 'desc' } }" 
                                aria-expanded="false" :class="{'active': $router.currentRoute.name == 'comments'}">
                                <i class="mdi mdi-message-text"></i>
                                <span class="hide-menu">Comment</span>
                            </router-link>
                        </li> -->
                        <li v-if="hasUiAccess(COMMENT) && !isAccountTest"
                            @click="showComment = !showComment, showChildChatSetting = false, showSession = false, showDashboard = false, showEmail = false">
                            <a class="waves-effect waves-dark" aria-expanded="false"
                                v-bind:class="{'active': $router.currentRoute.name == 'comments-fb' || $router.currentRoute.name == 'comments-ig'}"><i
                                    class="mdi mdi-comment"></i><span class="hide-menu">Komentar</span>
                                <span class="pull-right list-operator">
                                    <i class="mdi mdi-chevron-down" v-if="!showComment"></i>
                                    <i class="mdi mdi-chevron-up" v-else></i>
                                </span></a>
                        </li>
                        <span v-if="showComment">
                            <li>
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'comments-fb'}"
                                    aria-expanded="false" :to="{ name: 'comments-fb', query: { sortOrder: 'lastActive', sortDir: 'desc' } }" 
                                    class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-facebook-box"></i>
                                    <span class="hide-menu p-1"> Facebook</span>
                                    <span :class="{ 'badge-notif-active': $router.currentRoute.name == 'comments-fb' }"
                                        class="list-operator badge badge-notif" v-show="commentFbCount > 0">
                                        {{ commentFbCount }}
                                    </span>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'comments-ig'}"
                                    aria-expanded="false" :to="{ name: 'comments-ig', query: { sortOrder: 'lastActive', sortDir: 'desc' } }" 
                                    class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-instagram"></i>
                                    <span class="hide-menu p-1"> Instagram</span>
                                    <span :class="{ 'badge-notif-active': $router.currentRoute.name == 'comments-ig' }"
                                        class="list-operator badge badge-notif" v-show="commentIgCount > 0">
                                        {{ commentIgCount }}
                                    </span>
                                </router-link>
                            </li>
                        </span>
                        <li v-if="hasUiAccess(BROADCAST) && !isAccountTest"
                            @click="showChildChatSetting = !showChildChatSetting, showSession = false, showDashboard = false, showEmail = false">
                            <a class="waves-effect waves-dark" aria-expanded="false"
                                v-bind:class="{'active': $router.currentRoute.name == 'chatsetting' || $router.currentRoute.name == 'broadcast'}"><i
                                    class="mdi mdi-settings"></i><span class="hide-menu">Pesan Otomatis</span>
                                <span class="pull-right list-operator">
                                    <i class="mdi mdi-chevron-down" v-if="!showChildChatSetting"></i>
                                    <i class="mdi mdi-chevron-up" v-else></i>
                                </span></a>
                        </li>
                        <span v-if="showChildChatSetting">
                            <li>
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'autochatsetting'}"
                                    aria-expanded="false" to="/autochatsetting/"
                                    class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-file-document-box"></i>
                                    <span class="hide-menu p-1"> Template</span></router-link>
                            </li>
                            <li>
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'broadcast'}"
                                    aria-expanded="false" to="/broadcast/" class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-bullhorn"></i>
                                    <span class="hide-menu p-1"> Broadcast</span></router-link>
                            </li>
                        </span>
                        <li v-if="hasUiAccess(EMAIL) && !isAccountTest"
                            @click="showEmail = !showEmail, showChildChatSetting = false, showSession = false, showDashboard = false">
                            <a class="waves-effect waves-dark" aria-expanded="false"
                                v-bind:class="{'active': $router.currentRoute.name == 'email' || $router.currentRoute.name == 'myemail' || $router.currentRoute.name == 'allemail'}"><i
                                    class="mdi mdi-email"></i><span class="hide-menu"> Email</span>
                                <span class="pull-right list-operator">
                                    <i class="mdi mdi-chevron-down" v-if="!showEmail"></i>
                                    <i class="mdi mdi-chevron-up" v-else></i>
                                </span></a>
                        </li>
                        <span v-if="showEmail">
                            <li>
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'email'}"
                                    aria-expanded="false" to="/email" class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-inbox"></i>
                                    <span class="hide-menu p-1" :class="{'font-weight-bold': newEmail == true }"> Belum
                                        Ditangani</span>
                                    <span :class="{ 'badge-notif-active': $router.currentRoute.name == 'email' }"
                                        class="list-operator badge badge-notif" v-show="emailUnassignedCount > 0">
                                        {{ emailUnassignedCount }}
                                    </span>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'myemail'}"
                                    aria-expanded="false" to="/myemail" class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-account-check"></i>
                                    <span class="hide-menu p-1" :class="{'font-weight-bold': newMyEmail == true }">
                                        Email Saya</span>
                                    <span :class="{ 'badge-notif-active': $router.currentRoute.name == 'myemail'}"
                                        class="list-operator badge badge-notif" v-show="emailAssignedCount > 0">
                                        {{ emailAssignedCount }}
                                    </span>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'allemail'}"
                                    aria-expanded="false" to="/allemail" class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-message-text"></i>
                                    <span class="hide-menu p-1"> Semua Email</span>
                                    <span :class="{ 'badge-notif-active': $router.currentRoute.name == 'allemail' }"
                                        class="list-operator badge badge-notif" v-show="emailAllEmailCount > 0">
                                        {{ emailAllEmailCount }}
                                    </span>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'pendingemail'}"
                                    aria-expanded="false" to="/pendingemail"
                                    class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-email-open"></i>
                                    <span class="hide-menu p-1" :class="{'font-weight-bold': newPendingEmail == true }">
                                        Pending Email</span>
                                    <span :class="{ 'badge-notif-active': $router.currentRoute.name == 'pendingemail' }"
                                        class="list-operator badge badge-notif" v-show="emailPendingCount > 0">
                                        {{ emailPendingCount }}
                                    </span>
                                </router-link>
                            </li>
                        </span>
                        <li v-if="!isAccountTest"
                            @click="showSession = !showSession, showChildChatSetting = false, showDashboard = false, showEmail = false">
                            <a class="waves-effect waves-dark" aria-expanded="false"
                                v-bind:class="{'active': $router.currentRoute.name == 'session' || $router.currentRoute.name == 'report' || $router.currentRoute.name == 'label' || $router.currentRoute.name == 'report count'}"><i
                                    class="mdi mdi-file-document-box"></i><span class="hide-menu">Laporan</span>
                                <span class="pull-right list-operator">
                                    <i class="mdi mdi-chevron-down" v-if="!showSession"></i>
                                    <i class="mdi mdi-chevron-up" v-else></i>
                                </span></a>
                        </li>
                        <span v-if="showSession">
                            <li v-if="hasUiAccess(REPORT) && !isAccountTest">
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'session'}"
                                    aria-expanded="false" to="/session/" class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-file-document"></i>
                                    <span class="hide-menu p-1"> Session</span></router-link>
                            </li>
                            <li v-if="hasUiAccess(REPORT) && !isAccountTest">
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'report'}"
                                    aria-expanded="false" to="/report/" class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-file-document"></i>
                                    <span class="hide-menu p-1"> Sales & Return</span></router-link>
                            </li>
                            <li v-if="hasUiAccess(REPORT) && !isAccountTest">
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'label'}"
                                    aria-expanded="false" to="/label/" class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-file-document"></i>
                                    <span class="hide-menu p-1"> Daftar Label</span></router-link>
                            </li>
                            <li v-if="hasUiAccess(REPORT) && !isAccountTest">
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'report count'}"
                                    aria-expanded="false" to="/report-count/"
                                    class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-file-document"></i>
                                    <span class="hide-menu p-1"> Count Sales & Return</span></router-link>
                            </li>
                            <li v-if="!isAccountTest">
                                <router-link v-bind:class="{'active': $router.currentRoute.name == 'report cancel order'}"
                                    aria-expanded="false" to="/report-cancel-order/"
                                    class="waves-effect waves-dark p-l-40 mb-4">
                                    <i class="mdi mdi-file-document"></i>
                                    <span class="hide-menu p-1"> Pembatalan Order</span></router-link>
                            </li>
                        </span>
                        <li v-if="hasUiAccess(CONTACT) && !isAccountTest" @click="closeMenu">
                            <router-link class="waves-effect waves-dark" aria-expanded="false"
                                v-bind:class="{'active': $router.currentRoute.name == 'contact'}" to="/contact/"><i
                                    class="mdi mdi-account"></i><span class="hide-menu">Kontak</span></router-link>
                        </li>
                        <li v-if="hasUiAccess(GROUP) && !isAccountTest" @click="closeMenu">
                            <router-link class="waves-effect waves-dark" aria-expanded="false"
                                v-bind:class="{'active': $router.currentRoute.name == 'group'}" to="/group/"><i
                                    class="mdi mdi-account-multiple"></i><span class="hide-menu">Grup</span>
                            </router-link>
                        </li>
                        <li v-if="hasUiAccess(DASHBOARD) && !isAccountTest"
                            @click="showDashboard = !showDashboard, showSession = false, showChildChatSetting = false, showEmail = false">
                            <a class="waves-effect waves-dark" aria-expanded="false" :class="{
                                    'active': $router.currentRoute.name == 'dashboard' || 
                                    $router.currentRoute.name == 'dashboard chat'
                                }">
                                <i class="mdi mdi-chart-bar"></i>
                                <span class="hide-menu">Dashboard</span>
                                <span class="pull-right list-operator">
                                    <i class="mdi mdi-chevron-down" v-if="!showDashboard"></i>
                                    <i class="mdi mdi-chevron-up" v-else></i>
                                </span>
                            </a>
                        </li>
                        <span v-if="showDashboard">
                            <li>
                                <router-link class="waves-effect waves-dark p-l-40 mb-4" aria-expanded="false"
                                    to="/dashboard-chat">
                                    <i class="mdi mdi-message"></i>
                                    <span class="hide-menu p-1"> Chat</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link class="waves-effect waves-dark p-l-40 mb-4" aria-expanded="false"
                                    to="/dashboard-order">
                                    <i class="mdi mdi-cart"></i>
                                    <span class="hide-menu p-1"> Penjualan</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link class="waves-effect waves-dark p-l-40 mb-4" aria-expanded="false"
                                    to="/dashboard-complain">
                                    <i class="mdi mdi-alert"></i>
                                    <span class="hide-menu p-1"> Komplain</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link class="waves-effect waves-dark p-l-40 mb-4" aria-expanded="false"
                                    to="/dashboard-agent">
                                    <i class="mdi mdi-account-alert"></i>
                                    <span class="hide-menu p-1"> Agen</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link class="waves-effect waves-dark p-l-40 mb-4" aria-expanded="false"
                                    to="/dashboard-responsetime">
                                    <i class="mdi mdi-timetable"></i>
                                    <span class="hide-menu p-1"> Waktu Respon</span>
                                </router-link>
                            </li>
                        </span>
                    </ul>

                    <hr class="list-operator">
                    <div class="list-operator user-info text-secondary">Operator </div>
                    <br>
                    <div class="list-operator">
                        <div class="scroll-content">
                            <div class="text-muted mb-4" v-for="(item, index) in operatorList" :key="index">
                                <div v-if="item.status != true">
                                    <span v-if="now.diff(item.status, 'days') < 7">
                                        <div class="wrapp-img" style="margin-right: 10px;">
                                            <div class="initials">
                                                {{ changeInitialName(item.name) }}
                                            </div>
                                        </div>
                                        <div class="op-name">{{convertName(item.name)}}</div>
                                        <small class="text-muted"><i class="mdi mdi-circle text-muted"></i>
                                            <span class="">
                                                {{ formatDate(item.status) }}
                                            </span>
                                        </small>
                                    </span>
                                    <!-- <span v-if="now.diff(item.status, 'days') < 20">
                                        {{item.name}} - {{ formatDate(item.status) }}
                                    </span> -->
                                </div>
                                <div v-else>
                                    <div class="wrapp-img" style="margin-right: 10px;">
                                        <div class="initials">
                                            {{ changeInitialName(item.name) }}
                                        </div>
                                    </div>
                                    <div class="op-name">{{item.name}}</div>

                                    <small class="text-muted">
                                        <i class="mdi mdi-circle green-color"></i>
                                        <span> online</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <!-- End Sidebar navigation -->
                <div class="sidebar-footer" style="display: none;">
                    <!-- item-->
                    <a href="" class="btn btn-primary create-contact" data-toggle="modal"
                        data-target="#createContactModal">Tambah
                        Kontak</a>
                </div>
            </div>
            <!-- End Sidebar scroll-->
            <!-- Bottom points-->
            <!-- End Bottom points-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper" style="min-height: 203px;">
            <slot />
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <Logout ref="logout" />
        <ProfileEditor ref="profileEditor" />
        <LookupContact />
    </v-app>
</template>
<script>
    import Logout from '../components/Logout'
    import ProfileEditor from '../components/ProfileEditor'
    import LookupContact from '../components/LookupContact'
    import firebase from 'firebase'
    import axios from 'axios'
    var moment = require('moment');
    import {
        ALL_MSG,
        MY_CONV,
        UNASSIGNED,
        CONTACT,
        USER,
        COMMCH,
        GROUP,
        BROADCAST,
        REPORT,
        DASHBOARD,
        CALENDAR,
        PRESENCE,
        SETTING,
        EMAIL,
        COMMENT
    } from '../utils/ui-constant'

    import {
        mapState,
        mapActions,
        mapMutations
    } from 'vuex'

    const {
        toFriendlyDateTime
    } = require('../utils/date-utils');

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                fbConnListenerSet: state => state.session.fbConnListenerSet,
                internetConnected: state => state.session.internetConnected,
                unreadUnassigned: state => state.socket.unreadUnassigned.unreadCount,
                unreadMyChat: state => state.socket.unreadMyChat.unreadCount,
                emailUnassignedCount: state => state.socket.countEmail.unassigned ? state.socket.countEmail
                    .unassigned : 0,
                emailAssignedCount: state => state.socket.countEmail.assigned ? state.socket.countEmail.assigned :
                    0,
                emailAllEmailCount: state => state.socket.countEmail['all-email'] ? state.socket.countEmail[
                    'all-email'] : 0,
                emailPendingCount: state => state.socket.countEmail.pending ? state.socket.countEmail.pending : 0,
                newEmail: state => state.socket.newEmail ? state.socket.newEmail : 0,
                newMyEmail: state => state.socket.newMyEmail ? state.socket.newMyEmail : 0,
                newPendingEmail: state => state.socket.newPendingEmail ? state.socket.newPendingEmail : 0,
                isAccountTest: state => state.session.sessionInfo.userId == "bippofb",
                commentFbCount: state => state.socket.countComment.fb ? state.socket.countComment.fb : 0,
                commentIgCount: state => state.socket.countComment.ig ? state.socket.countComment.ig : 0
            })
        },
        watch: {
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            }
        },
        data: function () {
            return {
                showDashboard: false,
                showSession: false,
                showChildChatSetting: false,
                showEmail: false,
                showComment: false,
                ALL_MSG,
                MY_CONV,
                UNASSIGNED,
                CONTACT,
                USER,
                COMMCH,
                GROUP,
                BROADCAST,
                REPORT,
                DASHBOARD,
                CALENDAR,
                PRESENCE,
                SETTING,
                EMAIL,
                COMMENT,
                countNewMessage: 0,
                countMyNewMessage: 0,
                countAllNewMessage: 0,
                newMessageIsActive: false,
                mynewMessageIsActive: false,
                allMessageIsActive: false,
                file: '',
                urlImage: '',
                contactId: '',
                contactName: '',
                success: '',
                plugged: '',
                platform: '',
                isRealocaled: '',
                battery: '',
                alias: '',
                phoneNumbers: [],
                emailUser: '',
                nameUser: '',
                operatorList: [],
                fbPresence: {
                    path: '',
                    listener: null
                },
                fbWaConnector: {
                    path: '',
                    listener: null
                },
                now: moment(),
            }
        },
        created: function () {
            if (this.$helpers.isBlank(this.sessionInfo)) {
                this.$store.dispatch('session/initFbToken')
            } else {
                this.loadInitialData();
            }

        },
        async mounted() {
            try {
                var resp = await axios.get(process.env.VUE_APP_API_URL + '/auth/myprofile')
                var jsonData = resp.data;
                this.nameUser = jsonData.name
                this.emailUser = jsonData.email
                this.initOpenWaSessionsList()

                const routeName = this.$route.name

                if (routeName && (routeName == 'chatsetting' || routeName == 'broadcast')) {
                    this.showChildChatSetting = true
                }

                if (routeName && (routeName == 'session' || routeName == 'report' || routeName == 'label' ||
                        routeName == 'report count')) {
                    this.showSession = true
                }

                if (routeName && (routeName == 'dashboard chat' || routeName == 'dashboard order' || routeName ==
                        'dashboard complain' || routeName == 'dashboard agent' || routeName ==
                        'dashboard response time')) {
                    this.showDashboard = true
                }

                if (routeName && (routeName == 'email' || routeName == 'myemail' || routeName == 'allemail' ||
                        routeName == 'pendingemail')) {
                    this.showEmail = true
                }

                if (routeName && (routeName == 'comments-fb' || routeName == 'comments-ig')) {
                    this.showComment = true
                }

            } catch (error) {
                this.error = this.$helpers.createError(error)
            }
        },
        destroyed: function () {
            firebase.database().ref(this.fbWaConnector.path)
                .off("child_changed", this.fbWaConnector.listener)
        },
        methods: {
            ...mapActions({
                initOpenWaSessionsList: 'getActiveOpenWa'
            }),
            ...mapMutations({
                setFbConnListenerFlag: 'session/setFbConnListenerFlag',
                setInternetConnected: 'session/setInternetConnected'
            }),
            closeMenu() {
                this.showSession = false
                this.showChildChatSetting = false
                this.showDashboard = false
            },
            hasUiAccess(menu) {
                if (this.sessionInfo && this.sessionInfo.ui) {
                    return this.sessionInfo.ui.indexOf(menu) >= 0
                } else {
                    return false
                }
            },
            setupWaConnectorListener: function () {
                var self = this

                var initWaConnStatus = function (snapshot) {
                    var val = snapshot.val()

                    if (val) {
                        self.alias = val.alias
                        self.battery = val.battery
                        self.plugged = val.plugged
                        self.success = val.success
                        self.locale = val.locale
                        self.platform = val.platform
                    }
                }

                var updateWaConnector = childsnapshot => {
                    if (childsnapshot.key == 'alias') {
                        self.alias = childsnapshot.node_.value_;
                    }
                    if (childsnapshot.key == 'battery') {
                        self.battery = childsnapshot.node_.value_;
                    }

                    if (childsnapshot.key == 'locale') {
                        self.locale = childsnapshot.node_.value_;
                    }
                    if (childsnapshot.key == 'platform') {
                        self.platform = childsnapshot.node_.value_;
                    }
                    if (childsnapshot.key == 'plugged') {
                        self.plugged = childsnapshot.node_.value_;
                    }
                    if (childsnapshot.key == 'success') {
                        if (childsnapshot.node_.value_) {
                            self.success = 'Terhubung';
                        } else {
                            self.success = 'Tidak Terhubung';
                        }
                    }
                }


                axios.get(process.env.VUE_APP_API_URL + '/waconnector/tenant').then(response => {
                    self.fbWaConnector.path = self.sessionInfo.tenantId + '_' + process.env
                        .VUE_APP_ENV +
                        '/waconnector/' + response.data.connectorId
                    self.fbWaConnector.listener = firebase.database().ref(self.fbWaConnector.path)
                        .on("child_changed", updateWaConnector)
                    firebase.database().ref(self.fbWaConnector.path).once("value", initWaConnStatus);
                }).catch(error => {
                    console.error(error)
                })
            },
            setPresenceListener: function () {
                var presenceRef = firebase.database().ref(this.fbPresence.path)
                presenceRef.set(true)
                presenceRef.onDisconnect().set(firebase.database.ServerValue.TIMESTAMP)
            },
            loadInitialData: function () {
                var self = this
                if (!this.$helpers.isBlank(this.sessionInfo)) {
                    this.fbPresence.path = this.sessionInfo.tenantId + '_' + process.env
                        .VUE_APP_ENV + '/presence/' +
                        this.sessionInfo.userId


                    this.setupWaConnectorListener()
                    if (!this.fbConnListenerSet) {
                        this.setFbConnListenerFlag(true)
                        var connectedRef = firebase.database().ref(".info/connected")
                        connectedRef.on("value", function (snap) {
                            if (snap.val() === true) {
                                self.setInternetConnected(true)
                                self.setPresenceListener()
                            } else {
                                self.setInternetConnected(false)
                            }
                        })
                    }
                }

                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/presence').on(
                    "child_added", (res) => {
                        let status = res.val()

                        if (res.val() != true) status = moment(status)

                        let temp = [...this.operatorList, {
                            name: res.key,
                            status: status
                        }]

                        temp.sort((a, b) => a.status - b.status)

                        this.operatorList = temp
                    });

                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/presence').on(
                    "child_changed", (res) => {
                        let status = res.val()

                        if (res.val() != true) status = moment(status)
                        let temp = [...this.operatorList]
                        const index = temp.map(e => e.name).indexOf(res.key)
                        temp[index].status = status
                        temp.sort((a, b) => a.status - b.status)

                        this.operatorList = temp
                    });
            },
            confirmLogout: function () {
                this.$bvModal.show('logout-modal');
            },
            getWifiConection: function (success) {
                if (success == 'Terhubung') {
                    return 'mdi mdi-network-strength-4 green-color';
                } else {
                    return 'mdi mdi-network-strength-off-outline red-color';
                }
            },
            showProfileDialog: function () {
                this.$bvModal.show('edit-profile-modal');
            },
            batteryCssClass: function (battery) {
                if (battery > 90) {
                    return 'mdi mdi-battery green-color';
                } else if (battery > 79) {
                    return 'mdi mdi-battery-80 green-color';
                } else if (battery > 59) {
                    return 'mdi mdi-battery-60 green-color';
                } else if (battery > 39) {
                    return 'mdi mdi-battery-40 green-color';
                } else if (battery > 29) {
                    return 'mdi mdi-battery-30 orange-color';
                } else {
                    return 'mdi mdi-battery-20 red-color';
                }
            },
            formatDate: function (date) {
                return toFriendlyDateTime(date)
            },
            changeInitialName: function (name) {
                let changedName = String()

                name.split(" ").forEach(e => {
                    if (changedName.length < 2) changedName += e[0]
                })

                return changedName
            },
            convertName: function (name) {
                let changedName = []

                name.split("_").forEach(e => {
                    if (changedName.length < 2) changedName.push(e[0].toUpperCase() + e.slice(1))
                })

                return changedName.join(" ")
            },
        },
        props: ['currentModule'],
        components: {
            Logout,
            ProfileEditor,
            LookupContact
        }
    }
</script>
<style>
    @import '../assets/css/colors/blue.css';
    @import '../assets/css/spinners.css';
    @import '../assets/css/style.css';
    @import '../assets/css/custom.css';
</style>