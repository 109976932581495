<template>
    <b-modal id="logout-modal" title="Logout">
        <div class="modal-body">Apakah anda yakin keluar dari aplikasi?
        </div>
        <ErrorMessage v-bind:error="error" />
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-default" @click="$bvModal.hide('logout-modal')">Tidak</button>
            <button type="button" :disabled="onProgress" class="btn btn-primary tst3" @click="logout"><i
                    v-if="onProgress" class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Ya</button>
        </template>
    </b-modal>
</template>

<script>
    import axios from 'axios'
    import firebase from 'firebase'
    import ErrorMessage from '../components/ErrorMessage'
    import {
        mapState,
        mapActions
    } from 'vuex'

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                onProgress: false,
                error: {}
            }
        },
        methods: {
            ...mapActions({
                clearSession: 'clearSession'
            }),
            logout: async function () {
                this.error = {}
                this.onProgress = true
                try {
                    var axiosResp = axios.post(process.env.VUE_APP_API_URL + '/auth/logout')
                    try {
                        await firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env
                            .VUE_APP_ENV +
                            '/presence/' + this.sessionInfo.userId).set(firebase.database.ServerValue.TIMESTAMP)     
                    } catch (error) {
                        console.log(error);
                    }

                    await axiosResp
                    // await setStatusResp

                    this.clearSession()
                    await firebase.auth().signOut()
                    if (this.$socket.connected) this.$socket.disconnect()
                    this.$router.push('/login')
                    this.onProgress = false
                } catch (error) {
                    this.onProgress = false
                    this.error = this.$helpers.createError(error)
                }
            }
        },
        components: {
            ErrorMessage
        }
    }
</script>