export const ALL_MSG = 'all-messages'
export const MY_CONV = 'my-conversation'
export const UNASSIGNED = 'unassigned'
export const CONTACT = 'contact'
export const USER = 'user'
export const COMMCH = 'comm-channel'
export const GROUP = 'group'
export const BROADCAST = 'broadcast'
export const REPORT = 'report'
export const DASHBOARD = 'dashboard'
export const CALENDAR = 'calendar'
export const PRESENCE = 'set-presence'
export const SETTING = 'setting'
export const EMAIL = 'email'
export const COMMENT = 'comment'

export const STATE_RUN_FAILED = 'run-failed'
export const STATE_STARTING = 'starting'
export const STATE_LOGGED_IN = 'loggin-in-progress'
export const STATE_RUNNING = 'running'
export const STATE_TURNING_OFF = 'turning-off'
export const STATE_OFF = 'off'

var openWAStateMap = {}
openWAStateMap[STATE_RUN_FAILED] = 'Gagal Menjalankan WA'
openWAStateMap[STATE_STARTING] = 'Sedang menjalakan server'
openWAStateMap[STATE_LOGGED_IN] = 'Sedang proses login'
openWAStateMap[STATE_RUNNING] = 'Server berjalan'
openWAStateMap[STATE_TURNING_OFF] = 'Sedang mematikan server'
openWAStateMap[STATE_OFF] = 'Server mati'
openWAStateMap[null] = 'Server mati'

export const OPEN_WA_STATE_MAP = openWAStateMap