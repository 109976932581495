<template>
    <b-modal id="edit-profile-modal" title="Ubah Profil" @shown="initData">
        <div class="modal-body">
            <div class="img-wrapp text-center">
                <div class="container-img">
                    <img src="images/users/1.jpg" class="img-circle img-responsive img-profile image" id="iamge-id-user" alt="user">
                    <div class="middle">
                        <i class="mdi mdi-camera"></i>
                        <div class="clearfix"></div>
                        <a type="file" href="" class="edit-photo-link" id="file-photo" ref="file"
                            v-on:change="handleFileUpload">Ubah
                            Photo Profil</a>

                    </div>
                </div>
            </div>
            <form class="form-horizontal form-material">
                <div class="text-danger text-center"><label id="txt-danger-message"></label></div>
                <div class="text-success text-center"><label id="txt-success-message"></label></div>
                <div class="row">
                    <label for="m-user-name" class="col-sm-12 col-md-4 text-right control-label col-form-label">Nama
                        Lengkap</label>
                    <div class="col-sm-12 col-md-7 pt-0">
                        <input type="hidden" value="new"> <input v-model="name" type="text" id="m-user-name" class="form-control" style="cursor: auto;">
                    </div>
                </div>
                <div class="row">
                    <label for="m-user-email"
                        class="col-sm-12 col-md-4 text-right control-label col-form-label">Email</label>
                    <div class="col-sm-12 col-md-7 pt-0">
                        <input type="text" id="m-user-email" v-model="email" class="form-control"
                            style="cursor: auto;">
                    </div>
                </div>
                <div id="change-password">
                    <div class="row mt-2">
                        <label for="m-user-password-old"
                            class="col-sm-12 col-md-4 text-right control-label col-form-label">Kata Sandi
                            Lama</label>
                        <div class="col-sm-12 col-md-7 pt-0">
                            <input type="password" id="m-user-password-old" v-model="currentPassword"
                                class="form-control" autocomplete="off" style="cursor: auto;">
                        </div>
                    </div>
                    <div class="row">
                        <label for="m-user-password"
                            class="col-sm-12 col-md-4 text-right control-label col-form-label">Kata Sandi
                            Baru</label>
                        <div class="col-sm-12 col-md-7 pt-0">
                            <input type="password" id="m-user-password-new" v-model="newPassword.first"
                                class="form-control" autocomplete="off" style="cursor: auto;">
                        </div>
                        <div class="col-md-1 edit-icon col-sm-1 pt-2" v-if="!$helpers.isBlank(currentPassword)">
                            <a href="#"><i class="mdi mdi-check green-color" v-if="newPassValid"></i>
                                <i class="mdi mdi-close red-color" v-else></i></a>
                        </div>
                    </div>
                    <div class="row">
                        <label for="m-user-password-new-re"
                            class="col-sm-12 col-md-4 text-right control-label col-form-label">Ulangi
                            Kata
                            Sandi</label>
                        <div class="col-sm-12 col-md-7 pt-0">
                            <input type="password" id="m-user-password-new-re" v-model="newPassword.second"
                                class="form-control" autocomplete="off" style="cursor: auto;">
                        </div>
                        <div class="col-md-1 edit-icon col-sm-1 pt-2" v-if="!$helpers.isBlank(currentPassword)">
                            <a href="#"><i class="mdi mdi-check green-color" v-if="confPassValid"></i>
                                <i class="mdi mdi-close red-color" v-else></i></a>
                        </div>
                     <div class="col-sm-12 text-center">
                        <i v-if="loadOnProgress" class="fa fa-circle-o-notch fa-spin"></i>
                        <ErrorMessage v-bind:error="error" />
                     </div>
                    </div>
                </div>
            </form>
        </div>
        <template v-slot:modal-footer>
            <div class="">
                <a id="btn-cancel" class="btn btn-default waves-effect waves-light m-r-10"
                    @click="$bvModal.hide('edit-profile-modal')">
                    &nbsp;Batal</a>
                <a id="btn-save-password" @click="submit" 
                    class="btn btn-primary waves-effect waves-light">
                    <i v-if="saveOnProgress" class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Simpan</a>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import axios from 'axios'
    import ErrorMessage from '../components/ErrorMessage'
    export default {
        data: function () {
            return {
                saveOnProgress: false,
                loadOnProgress: false,
                name: '',
                email: '',
                currentPassword: '',
                newPassword: {
                    first: '',
                    second: ''
                },
                newPassValid: false,
                confPassValid: false,
                error: []
            }
        },
        watch: {
            'newPassword.first': function (val) {
                this.newPassValid = val && val.length > 8
            },
            'newPassword.second': function (val) {
                this.confPassValid = val == this.newPassword.first
            }
        },
        methods: {
            initData: async function () {
                this.saveOnProgress = false
                this.currentPassword = ''
                this.newPassword.first = ''
                this.newPassword.second = ''
                this.newPassValid = false
                this.confPassValid = false
                this.error = []

                this.loadOnProgress = true;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/auth/myprofile')
                    var jsonData = resp.data
                    this.name = jsonData.name
                    this.email = jsonData.email
                } catch (error) {
                    this.error = this.$helpers.createError(error)
                }
                this.loadOnProgress = false;
            },
            submit: async function () {

                if (!this.$helpers.isBlank(this.currentPassword) && (!this.newPassValid || !this.confPassValid)) {
                    this.error = this.$helpers.createError({
                        message: 'Password baru masih belum valid (panjang harus lebih dari 8 karakter dan pastikan konfirmasi password sudah sama dengan password yang and masukkan)'
                    })
                } else {
                    this.saveOnProgress = true;
                    try {
                        var putData = {
                            email: this.email,
                            name: this.name
                        }

                        if (!this.$helpers.isBlank(this.currentPassword)) {
                            putData.oldPassword = this.currentPassword
                            putData.password = this.newPassword.first
                        }

                        await axios.put(process.env.VUE_APP_API_URL + '/auth/myprofile', putData)
                        this.$bvModal.hide('edit-profile-modal')
                    } catch (error) {
                        this.error = this.$helpers.createError(error)
                    }
                    this.saveOnProgress = false
                }
            },
            handleFileUpload: function () {

            }
        },
        components: {
            ErrorMessage
        }
    }
</script>