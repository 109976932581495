<template>
    <b-modal id="new-message-modal" scrollable title="Pesan Baru: Pilih Kontak..." hide-footer @show="resetModal">
        <div class="chat-left-inner form-horizontal">
            <div class="form-material">
                <div class="input-group ">
                    <input type="text" class="form-control" id="uname" placeholder="Masukkan kata pencarian..."
                        v-model="searchTerm" @keyup.enter="loadContact">
                    <div class="input-group-addon pt-2">
                        <i class="ti-search"></i>
                    </div>
                </div>
            </div>
            <div class="form-material" v-if="!$helpers.isBlank(error)">
                <ErrorMessage v-bind:error="error" />
            </div>
            <div>
                <div class="scroll-content">
                    <ul class="chatonline user-list">
                        <li v-for="item in contactList" :key="item.id">
                            <a href="#" v-bind:class="{ active: item.selected }" @click="selectContact($event, item)">
                                <span class=""> <span class="user-name">{{ item.name }}</span>
                                    <small class="text-default">
                                        <div class="chat-content-thumb">{{ item.primaryPhoneNumber }}</div>
                                    </small>
                                </span>

                            </a>
                        </li>
                        <li id="contact-lookup-loader-indicator" v-bind:class="{'d-none':  !isLoading}">
                            <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </b-modal>
</template>

<script>
    import ErrorMessage from '../components/ErrorMessage'
    import axios from "axios";
    // const $ = require("jquery")

    var CONTACT_LOOKUP_ROW_PER_PAGE = 25;

    export default {
        data: function () {
            return {
                queryParams: {
                    status: 'open',
                    sortOrder: 'lastActive',
                    sortDir: 'desc',
                    custroles: '',
                    labels: '',
                    lastMessagesDirection: '',
                },
                currentPage: 0,
                hasMoreData: false,
                searchTerm: '',
                isTyping: false,
                contactList: [],
                contactId: '',
                contactName: '',
                phoneNumbers: [],
                isLoading: false,
                error: {}
            }
        },
        methods: {
            loadContact: async function () {
                this.contactList = [];
                this.currentPage = 1;
                this.hasMoreData = true;
                this.isLoading = true;
                this.error = {}
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/', {
                        params: {
                            page: this.currentPage,
                            term: this.searchTerm,
                            size: CONTACT_LOOKUP_ROW_PER_PAGE,
                            status: 'ACTIVE'
                        }
                    });

                    var jsonData = resp.data;
                    this.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.contactList = jsonData.content;
                } catch (error) {
                    this.error = this.$helpers.createError(error)
                }
                this.isLoading = false;
            },
            selectContact(event, item) {
                event.preventDefault()
                this.$bvModal.hide('new-message-modal')
                this.$router.push({
                    name: 'myconversation',
                    params: {
                        'id': encodeURIComponent(item.id)
                    },
                    query: this.queryParams
                });
            },
            resetModal() {
                this.searchTerm = String()
                this.contactList = Array()
            }
        },
        components: {
            ErrorMessage
        }
    }
</script>